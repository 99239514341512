import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { mobile } from 'styles/responsive';

interface ContentWrapperType {
  children?: ReactNode;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: ${mobile}) {
    gap: 30px;
  }
`;

export default function ContentWrapper({ children }: ContentWrapperType) {
  return <Container>{children}</Container>;
}
