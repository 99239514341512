import styled from '@emotion/styled';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mobile } from 'styles/responsive';

interface ContentTitleType {
  icon?: IconProp;
  title: string;
  subTitle?: string;
}

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: var(--fs-2xl);

  @media (max-width: ${mobile}) {
    font-size: var(--fs-xl);
  }
`;

const Title = styled.p`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: var(--fs-2xl);
  font-weight: 600;

  svg {
    width: var(--fs-3xl);
  }

  @media (max-width: ${mobile}) {
    font-size: var(--fs-xl);
  }
`;

const SubTitle = styled.p`
  margin-left: 44px;
  font-size: var(--fs-xl);

  @media (max-width: ${mobile}) {
    margin-left: 50px;
    font-size: 14px;
  }
`;

export default function ContentTitle({
  icon,
  title,
  subTitle,
}: ContentTitleType) {
  return (
    <TitleWrapper>
      <Title>
        {icon && <FontAwesomeIcon icon={icon}></FontAwesomeIcon>}
        {title}
      </Title>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
    </TitleWrapper>
  );
}
