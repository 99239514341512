import styled from '@emotion/styled';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { mobile, tabletL, tabletS } from 'styles/responsive';
import ContentWrapper from 'components/molecules/container/ContentWrapper';
import ContentTitle from 'components/atoms/Text/ContentTitle';

const TechWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: ${mobile}) {
    display: flex;
    flex-direction: column;
  }
`;

const Description = styled.p`
  font-size: 20px;

  @media (max-width: ${tabletL}) {
    font-size: 18px;
  }

  @media (max-width: ${mobile}) {
    font-size: 14px;
  }
`;

const EachTech = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 50px;
  border-radius: 10px;
  background: var(--point-bg);

  @media (max-width: ${tabletL}) {
    padding: 40px;
  }

  @media (max-width: ${tabletS}) {
    padding: 30px;
  }

  @media (max-width: ${mobile}) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
  }
`;

const TechList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;

  @media (max-width: ${mobile}) {
    margin-top: 10px;
  }
`;

const TechImg = styled.div`
  width: 100px;
  border-radius: 50%;
  background: #fff;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: ${tabletL}) {
    width: 80px;
  }

  @media (max-width: ${tabletS}) {
    width: 60px;
  }

  @media (max-width: ${mobile}) {
    width: 50px;
  }
`;

export default function TechStack() {
  const capableTechImg = [
    'javascript',
    'typescript',
    'react',
    'gatsby',
    'graphql',
    'emotion',
  ];
  const studyTechImg = ['redux', 'next-js', 'my-sql', 'mongo-db'];

  return (
    <ContentWrapper>
      <ContentTitle icon={faWandMagicSparkles} title={`기술 스택`} />
      <TechWrapper>
        <EachTech>
          <Description>다룰 줄 알아요!</Description>
          <TechList>
            {capableTechImg.map((img, i) => {
              return (
                <TechImg key={i}>
                  <img src={`/${img}.png`} alt={`${img} logo`} />
                </TechImg>
              );
            })}
          </TechList>
        </EachTech>
        <EachTech>
          <Description>공부 중이에요</Description>
          <TechList>
            {studyTechImg.map((img, i) => {
              return (
                <TechImg key={i}>
                  <img src={`/${img}.png`} alt={`${img} logo`} />
                </TechImg>
              );
            })}
          </TechList>
        </EachTech>
      </TechWrapper>
    </ContentWrapper>
  );
}
