import Template from 'components/templates/MainTemplate';
import MainContent from 'components/molecules/container/MainContent';
import Footer from 'components/organisms/footer/Footer';
import TechStack from 'components/organisms/about/TechStack';
import MainContainer from 'components/molecules/container/MainContainer';

export default function About() {
  return (
    <Template url={`https://nxnaxx.github.io/about`}>
      <MainContainer>
        <MainContent>
          <TechStack />
        </MainContent>
        <Footer />
      </MainContainer>
    </Template>
  );
}
